import React, { useEffect, useState } from 'react';
import './App.css';
import meimage from './meimage.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from "react-bootstrap";
import { faCheck, faCheckCircle, faCheckDouble, faCheckSquare, faEnvelope, faPhoneSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames';

function App(props) {
  const services = React.createRef();
  const company = React.createRef();
  const contact = React.createRef();

  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll())

    return () => {
      window.removeEventListener("scroll", () => handleScroll());
    }
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = currentScrollPos < 200 || (prevScrollpos > currentScrollPos);

    setPrevScrollpos(currentScrollPos);
    setVisible(visible);
  };

  const executeScroll = (element) => {
    element.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="app">
      <Navbar collapseOnSelect className={classnames("navbar-dark font-300", { "navbar--hidden font-300": !visible })} expand="md" fixed="top">
        <Navbar.Brand className="nav-brand" href="#services">BITVENTURE<br></br> <span style={{ marginLeft: "5px", fontSize: "16px" }}>Microsoft Stack Developer C# | .NET | Azure</span></Navbar.Brand>
        <Navbar.Toggle style={{ color: "white" }} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse style={{ color: "white" }} id="responsive-navbar-nav">
          <Nav className="mr-auto">
          </Nav>
          <Nav>
            <Nav.Link style={{ fontSize: "20px" }} onClick={() => executeScroll(services)}>PALVELUT</Nav.Link>
            <Nav.Link style={{ fontSize: "20px" }} onClick={() => executeScroll(company)}>YRITYS</Nav.Link>
            <Nav.Link style={{ fontSize: "20px" }} onClick={() => executeScroll(contact)}>YHTEYSTIEDOT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="app-background">
      </div>
      <div className="container-fluid">
        <div ref={services} id="services" className="mt-5 row">
          <div style={{ margin: 'auto' }} className="col-md-6 col-sm-12">
            <img className="ownimg" src={meimage}></img>
          </div>
          <div className="mt-2 col-md-6 col-sm-12 font-300">
            <br></br>
            <h2 className="font-300">PALVELUT</h2>
            <br></br>
            <table>
              <tbody>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <FontAwesomeIcon className="fa-lg green" icon={faCheck} />
                  </td>
                  <td style={{ margin: '0px 0px 0px 10px' }}>
                    <p className="left">Palvelen vaativissa ohjelmistokehityshankkeissa.</p>
                  </td>
                </tr>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <FontAwesomeIcon className="fa-lg green" icon={faCheck} />
                  </td>
                  <td style={{ margin: '0px 0px 0px 10px' }}>
                    <p className="left">Full Stack -sovelluskehitys on intohimoni.</p>
                  </td>
                </tr>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <FontAwesomeIcon className="fa-lg green" icon={faCheck} />
                  </td>
                  <td style={{ margin: '0px 0px 0px 10px' }}>
                    <p className="left">Olen panostanut erityisesti Microsoftin teknologioihin, joista minulla on noin 15 vuoden kokemus.</p>
                  </td>
                </tr>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <FontAwesomeIcon className="fa-lg green" icon={faCheck} />
                  </td>
                  <td style={{ margin: '0px 0px 0px 10px' }}>
                    <p className="left">.NET, .NET Core, ASP.NET Core ovat teknologioina erityisen tuttuja.</p>
                  </td>
                </tr>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <FontAwesomeIcon className="fa-lg green" icon={faCheck} />
                  </td>
                  <td style={{ margin: '0px 0px 0px 10px' }}>
                    <p className="left">C# ja SQL kielet ovat vahvuuteni.</p>
                  </td>
                </tr>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <FontAwesomeIcon className="fa-lg green" icon={faCheck} />
                  </td>
                  <td style={{ margin: '0px 0px 0px 10px' }}>
                    <p className="left">Tunnen hyvin useat Azuren palvelut, kuten Application Insights, App Service, Azure SQL, Service Bus, Logic Apps jne.</p>
                  </td>
                </tr>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <FontAwesomeIcon className="fa-lg green" icon={faCheck} />
                  </td>
                  <td style={{ margin: '0px 0px 0px 10px' }}>
                    <p className="left">Minulla on kattava kokemus sekä Web- että työpöytäsovellusten koodaamisesta.</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <p className="left">Muita tuttuja teknologioita ja työkaluja ovat esimerkiksi:</p>
            <ul>
              <li>
                <p className="left">
                  Angular, Aurelia, JavaScript, TypeScript, CSS, HTML
                </p>
              </li>
              <li>
                <p className="left">
                  TeamCity ja Octopus (CI/CD), Azure DevOps, Jira, Confluence
                </p>
              </li>
              <li>
                <p className="left">
                  WPF, UWP, Xamarin
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div ref={company} id="company" className="row font-300">
          <div className="col-md-12 col-sm-12">
            <h2 className="mt-5 font-300" >YRITYS</h2>
            <br></br>
            <p className="right">Teen töitä pääsääntöisesti tuntihinnoittelulla.</p>
            <p className="right">Luotettavaa ja pitkäjänteistä tekemistä.</p>
            <p className="right">Sitoudun asiakkaani tarpeisiin.</p>
          </div>
        </div>
        <div ref={contact} id="contact" className="row font-300">
          <div className="backgrounddark col-xs-12 col-md-3">
          </div>
          <div className="backgrounddark col-xs-12 col-md-3">
            <p className="left mt-3 mb-1 colorwhite font-weight-bold">Bitventure Oy</p>
            <p className="left colorwhite mt-0 mb-0">Ahmalantie 10 B</p>
            <p className="left colorwhite mt-0 mb-0">90150 Oulu</p>
            <p className="left colorwhite mt-0 mb-4">Y-tunnus: 3236265-5</p>
          </div>
          <div className="backgrounddark col-xs-12 col-md-6">
            <p className="left mt-3 mb-2 colorwhite font-weight-bold">Ota yhteyttä:</p>
            <p className="left colorwhite mt-1 mb-0">Ilkka Joentakanen</p>
            <p className="left mt-1 mb-0"><FontAwesomeIcon className="colorwhite fa-lg" icon={faPhoneSquare} /><a className="left colorwhite" href="tel:0407609925">  0407609925 </a></p>
            <p className="left mt-1 mb-4"><FontAwesomeIcon className="colorwhite fa-lg" icon={faEnvelope} /><a className="left colorwhite" href="mailto:ilkka.joentakanen@bitventure.fi">  ilkka.joentakanen@bitventure.fi</a></p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default App;
